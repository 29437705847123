import React from 'react';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';

import './pharmacy-lookup.style.scss';

interface PharmacyListProps {
    searchText: string;
    pharmacies: string[];
    activeIndex: number;
    handleClick: (arg0: string, listIndex: number) => void;
}

export const PharmacyList: React.FC<PharmacyListProps> = ({ searchText, pharmacies, activeIndex, handleClick }) => {
    if (!Array.isArray(pharmacies)) {
        return null;
    }

    const boldString = (str: string, substr: string) => {
        const boldedString = `${str} `.replace(RegExp(substr, 'ig'), `<span class="btn-text-bold">${substr}</span>`);
        return {
            __html: sanitizeHtml(boldedString, {
                allowedTags: ['span'],
                allowedAttributes: {
                    span: ['class']
                },
                allowedClasses: {
                    span: ['btn-text-bold']
                }
            })
        };
    };

    const userSearchedText = searchText?.toUpperCase() || '';

    return (
        <ul
            className={'pharmacy-list'}
            id="pharmacy-list-listbox"
            role={'listbox'}
            onMouseDown={(e) => e.preventDefault()} // don't trigger blur event on mousedown event
        >
            {pharmacies.map((pharmacy, idx: number) => {
                return (
                    <li
                        role={'option'}
                        aria-selected={activeIndex === idx ? 'true' : 'false'}
                        className={`pharmacy ${activeIndex === idx ? 'active' : ''}`}
                        key={`pharmacy-list-item-${pharmacy}-${idx}`}
                    >
                        <Button
                            variant="text"
                            type="button"
                            className="my-2 mx-md-2 d-block sm-full d-sm-inline text-dark"
                            onClick={() => handleClick(pharmacy, idx)}
                        >
                            <span dangerouslySetInnerHTML={boldString(pharmacy, userSearchedText)} />
                        </Button>
                    </li>
                );
            })}
        </ul>
    );
};
